import React, { useRef, useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Container, Accordion, Grid, TextField, Select
  , Switch, InputTable, Popover
  , Centralizer, ChangeDialog, Button, DataTable,
  Group, ErrorMessage, Dialog, DialogDataType
}
  from "../components"
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { areIdentical, cn, countryList, deepCopyOf, logger, sleep } from "../utils"
import { useLogout, useJSONState, useTranslate } from '../hooks';
import { ShipmentType, shipmentSchema } from '../types/Shipments';
import { isRequired } from '../utils';
import { ZodError, set } from 'zod';
import { GoodslineType } from '../types/Goodsline';
import { DocumentType } from '../types/Document';
import { AxiosError } from 'axios';
import { add, format } from 'date-fns';
import { FaBars, FaCircleExclamation, FaFile, FaFilePdf, FaPrint } from "react-icons/fa6";
import { toast } from 'sonner';
import { WebsocketContext } from '../WebsocketContext';
import { WebsocketApi } from '../WebsocketApi';
import { AddressType } from '../types';
import { DeliveryType } from '../types/DeliveryType';

function Shipment() {
  const debug = false

  const { shipmentId } = useParams();
  const api = useAxiosPrivate();
  const navigate = useNavigate();
  const [changed, setChanged] = useState(false) //Checks if form has changed
  const submitForm = useRef<HTMLFormElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { t, tZod } = useTranslate();
  const [deliveryTypes, setDeliveryTypes] = useState<DeliveryType[]>([])
  const [ addresses, setAddresses ] = useState([])
  const [originalShipment, setOriginalShipment] = useState<ShipmentType>(); //The original Shipment
  const [shipment, setProp, setShipment] = useJSONState<ShipmentType | undefined>() //The shown and maybe changed shipment
  const [validationErrors, setValidationErrors] = useState<ZodError<ShipmentType> | null>(null)
  const [ dialog, setDialog ] = useState<DialogDataType>()

  const locked = (shipment?.status && shipment?.status > 60) as boolean

  const { data, sendJson } = useContext(WebsocketContext)

  // useEffect(() => {
  //   document.addEventListener("click", (e) => {
  //     document.getElementById("shipmentMenu")?.classList.add("hidden")
  //   })

  //   return () => {
  //     document.removeEventListener("click", () => { })
  //   }
  // }, [])

  // Listen for websocket messages
  useEffect(() => {
    if (data) {
      if (data.type === 'PrintEvent') {
        getShipmentInfo()
        if (data.status === 'success') {
          toast.info(data.message)
        }
       /* if (data.status === 'error') {
          toast.error(data.message)
        } else if (data.status === 'success') {
          toast.info(data.message)
        }*/
      }
    } console.log({data})
  }, [data])

  useEffect(() => {
    if(shipment && !originalShipment) return setChanged(true)
    if(!shipment || !originalShipment) return setChanged(false);

    if(areIdentical(shipment, originalShipment, ["isBooked", "noteInternal"]))
      setChanged(false)
    else  
      setChanged(true)

    setValidationErrors(null)
  }, [shipment, originalShipment])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    console.log("Shipment.tsx getShipmentInfo()")
    getShipmentInfo()

    return () => {
      isMounted = false;
      controller.abort()
    }
  }, [shipmentId])

  const getShipmentInfo = async () => {
    try {
      //sendJson({ type: "info", shipmentId: shipmentId })        
      const response = await api.get({endpoint: "/shipment/" + (shipmentId ?? "new"), mount:null})      
      let { shipmentData, addressRegister,  deliveryTypes } = response?.data;

      if(shipmentData){
        shipmentData.goodsLines = shipmentData.goodsLines ?? []
        shipmentData.documents = shipmentData.documents ?? []
      }else{
        shipmentData = {
          status: 0,
          trpPayer: 1,
          countryCode: "SE",
          goodsLines: [{type:"PC"}] as GoodslineType[],
          documents: [] as DocumentType[]
        } as ShipmentType
      }     

      console.log({shipmentData})

      if(shipmentData){
        setShipment(shipmentData);
        setOriginalShipment(shipmentData)
      }
      setAddresses(addressRegister ?? [])
      setDeliveryTypes(deliveryTypes ?? [])
    } catch (e) {
      if (e?.response?.status === 404) navigate("/shipments", { state: { dialog: { title: "Felaktig länk", message: "Sändningen existerar inte", type: "delete" } } })
      else {
        console.error(e)
        // logout()
      }
    }
  }

  const checkZipCode = async (zipCode: string, countryCode: string) => {
    if (zipCode.length < 3) return
    const response = await api.get({
      endpoint: "/common/checkzipcode",
      params: { zipCode, countryCode },
      mount: null
    })
    if (response.data) {
      if (response.data.valid)
        setProp(response.data.data?.city, 'city')
    }
  }

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    saveShipment()
  }

  const saveShipment = async (shipmentData?: ShipmentType) => {    
    if (locked) return

    const result = shipmentSchema.safeParse(shipmentData || shipment)
    
    if (!result.success) {
      setValidationErrors(tZod(result.error))
      return false
    }              

    try {
      let requestBody = deepCopyOf(shipmentData || shipment)
      requestBody.receiverPays = requestBody.receiverPays ? 2 : 1

      const response = await api.put({
        endpoint: `/shipment${shipment?.shipmentId ? `/${shipment.shipmentId}` : ''}`,
        data: requestBody,
        mount:{loadingText: t("saving")}
      })

      if(!shipmentData || shipmentData?.shipmentId){
        setOriginalShipment(shipmentData || shipment)
        if(shipmentData) setShipment(shipmentData)
        setValidationErrors(null)
      }

      if (response.data.shipmentId !== shipment?.shipmentId){
        if(shipmentId) toast.success(t("shipment.copyCreated"))
        navigate(`/shipment/${response.data.shipmentId}`)
      }else{
        toast.success(t("saved"))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const printDocuments = async (docGUID?:string|undefined|null, preview:boolean=false) => {
    try {
      if (submitForm.current?.checkValidity()) {
        await api.post({
          endpoint: `/shipment/${shipmentId}/print`,
          data: {docGUID, preview},
          mount: {
            loadingText: t("printing"),
            onLoadText: t("SentToPrinter")
          }
        }, )
        await getShipmentInfo()
      } else {
        submitButtonRef.current?.click();
      }
    } catch (err) {
      console.error(err)
    }
  }

  const bookShipment = async () => {
    if (submitForm.current?.checkValidity()) {
      try{
        await api.post({
          endpoint: `/shipment/${shipmentId}/book`,
          mount: {
            loadingText: t("booking"),
            onLoadText: t("booked")
          }
        })
      }catch(e){
        console.error(e)        
      }
      await getShipmentInfo()
    } else {
      submitButtonRef.current?.click();
    }
  }

  return (
    <>
      <Centralizer
        style={{
          position: "fixed",
          top: "50px",
          bottom: "0px",
          overflow: "auto",
          alignItems: "start",
          width: "100vw"
        }}
      >
        <Container style={{
          width: "clamp(1000px, 80%, 80vw)",
          maxHeight: "unset",
          marginBottom: "3rem"
        }}>

        {((data && data.message) || (originalShipment && originalShipment.ValidationError)) && 
        <ErrorMessage><div>{data?.message ?? originalShipment?.ValidationError}</div></ErrorMessage>
        }

          <form onSubmit={(e) => handleOnSubmit(e)} ref={submitForm}>
            <Container>
              {shipmentId ? (
                <Grid>
                  <TextField className="w-auto" readOnly={true} disabled={true} value={t(`ta_statuses.${shipment?.status}`)}>Status</TextField>
                  <Button _type="button" onClick={() => printDocuments()}>Skriv ut dokument</Button>
                  {(!shipment?.isBooked && shipment?.status !== 90) ?
                    <Button _type="button" onClick={() => bookShipment()}>{t("bookBooking")}</Button>
                    : <></>
                  }
                  {(shipment?.isBooked && shipment?.status !== 90) ?
                    <Button _type="button" variant='delete' onClick={bookShipment}>{t("cancelBooking")}</Button>
                    : <></>
                  }
                  <p>{t("shipmentId")}: {shipmentId}</p>
                  <button type="submit" style={{ display: "none" }} ref={submitButtonRef} ></button>


                  <div className='flex-1 flex justify-end mr-2 relative'>
                    <Popover 
                      options={[
                        {name: t("internalInfo"), onClick:() => {
                          setDialog({
                            noteInternal:{type:"textArea", name:"Name", placeholder:"Internal notes..", value:shipment?.noteInternal},
                          })
                        }},
                        {name: t("createShipmentCopy"), onClick:() => {
                          let shipmentCopy = deepCopyOf(shipment)
                          delete shipmentCopy.shipmentId
                          saveShipment(shipmentCopy)
                        }},                        
                        {name: t("cancelShipment"), onClick:() => {
                          const cancelShipment = {...shipment, status: 90} as ShipmentType
                          saveShipment(cancelShipment)
                        }},
                      ]}
                    >
                      <FaBars />
                    </Popover>
                  </div>
                </Grid>
              ) : (<></>)}
              <Accordion label={t("receiverAddress")} defaultOpen={true}>
                <Container className='pb-0'>             
                  <Grid>
                    <Select 
                      label={t("receiverAddress")}
                      data={addresses}
                      value={shipment?.companyName}
                      isDatalist={true}
                      onChange={(e) => {
                        let address = addresses.find((a:any) => a.value === e.target.value) as (AddressType | undefined)
                        if(address === undefined){
                          setProp(e.target.value, "companyName")
                          return
                        }
                        
                        setShipment((prev: ShipmentType | undefined) => {
                          return {...prev,...address,companyName: address?.name} as ShipmentType
                        })                          
                      }}
                      error={validationErrors?.errors.find(e => e.path[0] === "companyName")?.message}
                    />
                    <TextField
                      required={isRequired('street1', shipmentSchema)}
                      value={shipment?.street1}
                      onChange={(e) => setProp(e.target.value, 'street1')}
                      error={validationErrors?.errors.find(e => e.path[0] === "street1")?.message}
                      readOnly={locked}
                    >{t("street1")}</TextField>
                    <TextField
                      required={isRequired('street2',shipmentSchema)}
                      value={shipment?.street2}
                      onChange={(e) => setProp(e.target.value, 'street2')}
                      error={validationErrors?.errors.find(e => e.path[0] === "street2")?.message}
                      readOnly={locked}
                    >{t("street2")}</TextField>
                    <Select
                      required={isRequired('countryCode',shipmentSchema)}
                      label="Country"
                      data={countryList}
                      value={shipment?.countryCode}
                      onChange={(e) => { setProp(e.target.value, 'countryCode') }}
                      readOnly={locked}
                    ></Select>
                    <TextField
                      required={isRequired('zipCode',shipmentSchema)}
                      value={shipment?.zipCode}
                      onChange={(e) => setProp(e.target.value, 'zipCode')}
                      onBlur={(e) => checkZipCode(e.target.value, shipment?.countryCode || "")}
                      error={validationErrors?.errors.find(e => e.path[0] === "zipCode")?.message}
                      readOnly={locked}
                    >{t("zipCode")}</TextField>
                    <TextField
                      required={isRequired('city',shipmentSchema)}
                      value={shipment?.city}
                      onChange={(e) => setProp(e.target.value, 'city')}
                      error={validationErrors?.errors.find(e => e.path[0] === "city")?.message}
                      readOnly={locked}
                    >{t("city")}</TextField>

                    <Switch
                      label={t("paysFreight")}
                      checked={shipment?.trpPayer === 2}
                      onChange={(e) => setProp(e.currentTarget.checked ? 2 : 1, 'trpPayer')}
                      disabled={locked}
                    ></Switch>
                  </Grid>
                  <Group label={t("contactInfo")}>
                    <Grid>
                      <TextField
                        required={isRequired('contactName', shipmentSchema)}
                        value={shipment?.contactName}
                        onChange={(e) => setProp(e.target.value, 'contactName')}
                        error={validationErrors?.errors.find(e => e.path[0] === "contactName")?.message}
                        readOnly={locked}
                      >{t("contactName")}</TextField>
                      <TextField
                        required={isRequired('contactPhone', shipmentSchema)}
                        _type="tel"
                        value={shipment?.contactPhone}
                        onChange={(e) => setProp(e.target.value, 'contactPhone')}
                        error={validationErrors?.errors.find(e => e.path[0] === "contactPhone")?.message}
                        readOnly={locked}
                      >{t("contactPhone")}</TextField>
                      <TextField
                        required={isRequired('contactEmail', shipmentSchema)}
                        _type="email"
                        value={shipment?.contactEmail}
                        onChange={(e) => setProp(e.target.value, 'contactEmail')}
                        error={validationErrors?.errors.find(e => e.path[0] === "contactEmail")?.message}
                        readOnly={locked}
                      >{t("contactEmail")}</TextField>
                    </Grid>
                  </Group>
                </Container>
              </Accordion>
              <Accordion label="Transport" defaultOpen={true}>
                <Container className='pb-0'>
                  <Grid>
                    <Select
                      data={deliveryTypes}
                      blankOption={true}
                      label={t("deliveryType")}
                      required={isRequired("deliveryType", shipmentSchema)}
                      value={shipment?.deliveryType || ""}
                      onChange={(e) => {
                        let deliveryType = e.target.value
                        let gaNo = deliveryTypes.find(dt => dt.value === deliveryType)?.gano
                        setShipment(s => {
                          let n = {} as ShipmentType
                          if (s) n = {...s}
                          n.deliveryType = deliveryType
                          n.gaNo = gaNo ?? ""
                          return n
                        })
                      }}
                      // onChange={(e) => {
                      //   const deliveryType = deliveryTypes?.find((d: any) => d.deliveryType === e.target.value) as any
                      //   const cd = carrierData?.find((c: any) => c.value === deliveryType.carrierId) as any
                      //   const gaNo = cd?.gaNo

                      //   setShipment((prev: any) => {
                      //     return {
                      //       ...prev,
                      //       deliveryType: e.target.value,
                      //       gaNo: gaNo
                      //     } as ShipmentType
                      //   })
                      //   // setProp(e.target.value, 'deliveryType')
                      //   // setProp(gaNo, 'gaNo')
                      // }}
                      error={validationErrors?.errors.find(e => e.path[0] === "deliveryType")?.message}
                      readOnly={locked}
                    />
                    <TextField
                      required={isRequired('dispDate', shipmentSchema)}
                      _type="date"
                      value={shipment?.dispDate ? format(new Date(shipment?.dispDate), 'yyyy-MM-dd') : ""}
                      onChange={(e) => setProp(format(new Date(e.target.value), 'yyyy-MM-dd'), 'dispDate')}
                      error={validationErrors?.errors.find(e => e.path[0] === "dispDate")?.message}
                      readOnly={locked}
                    >{t("dispDate")}</TextField>
                    <TextField
                      required={isRequired('gaNo', shipmentSchema)}
                      value={shipment?.gaNo}
                      onChange={(e) => setProp(e.target.value, 'gaNo')}
                      error={validationErrors?.errors.find(e => e.path[0] === "gaNo")?.message}
                      readOnly={locked}
                    >{t("gaNo")}</TextField>
                    <>
                    {
                      shipment?.neededFields && shipment?.neededFields?.map?.((nf:any, i:number) => {
                        return (
                          <TextField
                          key={i}
                          required={nf.required}
                          value={nf.value || ""}
                          onChange={(e) => setProp(e.target.value, "neededFields", i, "value")}
                        >{nf.name}</TextField>)
                      })
                    }
                    </>
                  </Grid>
                </Container>
              </Accordion>
              <Accordion label={t("goods")} defaultOpen={true}>
                <InputTable
                  data={shipment?.goodsLines as any[] || []}
                  zodErrors={validationErrors as ZodError<ShipmentType>}
                  headers={{
                    quantity: { type: "number", required: true, readOnly: locked },
                    type: {
                      type: "text",
                      required: true,
                      options: [
                        { name: "Paket", value: "PC" },
                        { name: "Pall", value: "PX" },
                        { name: "EUR-Pall", value: "PE" }
                      ],
                      readOnly: locked,
                    },
                    weight: { type: "number", required: true, adornment: "kg", readOnly: locked, min:0 },
                    description: { type: "text", readOnly: locked },
                    length: { type: "number", adornment: "cm", readOnly: locked, min:0, step: 0.1 },
                    width: { type: "number", adornment: "cm", readOnly: locked, min:0, step: 0.1 },
                    height: { type: "number", adornment: "cm", readOnly: locked, min:0, step: 0.1 },
                    volume: { type: "number", adornment: "M3", readOnly: locked, min:0, step: 0.01 },
                    loadmeters: { type: "number", min:0 },
                  }}
                  addable={!locked}
                  deleteable={locked ? false: 1}
                  setProp={setProp}
                  propPath="goodsLines"
                />
              </Accordion>
              <Accordion label={<span className="flex gap-2"><FaPrint /> {t("documents")}</span>} defaultOpen={true}>
                {shipment && shipment.documents?.map((d: DocumentType, i: number) => {
                  return (
                    <Popover 
                      key={i}
                      options={[
                        {name: t("print"), onClick:() => printDocuments(d.docGUID, false)},
                        {name: t("preview"), onClick:() => printDocuments(d.docGUID, true)},
                      ]}
                    >
                      <div key={i} className={
                        cn("w-auto md:w-80 flex gap-5 items-center cursor-pointer  rounded-md mb-1 p-2",d.printStatus == "10" ? 'bg-gray-200' : d.printStatus == "20" ? 'bg-yellow-100' :
                        d.printStatus == "22" ? 'bg-blue-200' : d.printStatus == "25" ? 'bg-red-200' : d.printStatus == "30" ? 'bg-green-200' : d.printStatus == "90" ? 'bg-orange-200' : 'bg-gray-200')}>
                          
                        <div
                          className={
                          cn("flex gap-2 items-center text-sm font-bold",
                          d.printStatus == "10" ? 'text-gray-600' : d.printStatus == "20" ? 'text-yellow-600' :
                          d.printStatus == "22" ? 'text-blue-600' : d.printStatus == "25" ? 'text-red-600' : d.printStatus == "30" ? 'text-green-600' : d.printStatus == "90" ? 'text-orange-600' : 'text-gray-600')}><div className={
                          cn("p-3 rounded-md",
                          d.printStatus == "10" ? 'bg-gray-300' : d.printStatus == "20" ? 'bg-yellow-300' :
                          d.printStatus == "22" ? 'bg-blue-300' : d.printStatus == "25" ? 'bg-red-300' : d.printStatus == "30" ? 'bg-green-300' : d.printStatus == "90" ? 'bg-orange-300' : 'bg-gray-300')}><FaFile /></div>{t(d.documentName ?? "")}
                        </div>
                      </div>
                    </Popover>)
                })
                }                  
              </Accordion>           
            </Container>
            <ChangeDialog show={changed}
              undo={(!shipmentId ? undefined : async () => {
                setShipment(originalShipment)
                setTimeout(() => setChanged(false), 10)
              })}
            />
          </form>
        </Container>
      </Centralizer>

      {debug && <pre className='-z-50 absolute left-1 top-24 font-mono text-xs' >
        {JSON.stringify(shipment, undefined, 2)}
      </pre>}
      <Dialog 
        data={dialog}
        callback={(d) => {
          setProp(d.noteInternal, "noteInternal")
          setDialog(undefined)
        }}
      />
    </>
  )
}

export default Shipment