import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useJSONState, useLogout, useTranslate } from '../hooks';
import "../assets/style/Shipments.css"
import { TextField, Button, ConfirmDialog, Grid, Container, Group, Centralizer, Switch } from '../components';
import NewShipment from "./NewShipment"
import { ShipmentType } from '../types/Shipments';
import { ConfirmDialogProps } from '../components/Utils/ConfirmDialog';
import Shipment from './Shipment';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaCircle, FaMagnifyingGlass } from 'react-icons/fa6';
import TourStepper from "../components/Surfaces/TourStepper"
import { toast } from 'sonner';

export default function Shipments() {
  // Hooks
  const api = useAxiosPrivate();
  const logout = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const [ hideTour, setHideTour ] = useState(localStorage.hideTour)

  // States
  const [shipments, setShipments] = useState<ShipmentType[]>();
  const [newShipmentForm, setNewShipmentForm] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>();
  const [showInterval, setShowInterval] = useState(false);
  const [searchFields, setSearchField, setSearchFields] = useJSONState({
    trackingNo: undefined,
    orderNo: undefined,
    receiver: undefined,
    dispDate: undefined,
    carrier: undefined,
    status: undefined,
    shipmentId: undefined,
    dispDateFrom: undefined,
    dispDateTo: undefined,
  })

  // Defaults
  const dialogDefault = {
    title: t("NoShipmentFound"),
    message: t("VerifySearchConditions"),
    noCancel: true,
    type: "info",
    onOk: () => { setConfirmDialog(undefined) }
  } as ConfirmDialogProps

  const searchShipment = async (e: any, initial:boolean=false) => {
    if(e) e.preventDefault()
    let searchData = {} as any;

    if(initial){
      let sStorage = sessionStorage.getItem("searchFields");
      searchData = JSON.parse(sStorage ?? "{}")
      if(Object.keys(searchData).length == 0) return
      setSearchFields({...searchData})
    } 
    else
    {
      searchData = {...searchFields}
      sessionStorage.setItem("searchFields", JSON.stringify(searchData))
    }    

    if (!searchData || typeof searchData != "object") return

    try {
      Object.keys(searchData).forEach(field => {
        let val = searchData[field]
        if (typeof val === "string") {
          if (field.toLocaleLowerCase().indexOf('date') > -1) {
            searchData[field] = val.replaceAll('-', '')
          } else {
            val = val.replaceAll('_', '[_]')
            val = val.replaceAll('%', '[%]')
            searchData[field] = `%${val}%`
          }
        }
      })
      let response = await api.post({
        endpoint: "/ShipmentsList",
        data: searchData,
        mount: null
      })
      if (response.data.length < 1) {
        if(!initial) toast.info(t("NoShipmentFound") + ".\n" + t("VerifySearchConditions"))
        return
      }
      setShipments(response.data)
    } catch (e) {
      console.error(e)
      logout()
    }    
  }

  useEffect(() => {
    if ((location?.state as any)?.dialog !== undefined) {
      setConfirmDialog({ ...dialogDefault, ...(location?.state as any)?.dialog })
      window.history.replaceState({}, document.title)
      console.log(location)
    }
    searchShipment(null, true)
  }, [])

  const props = (key: string) => {
    return {
      value: searchFields[key],
      onChange: (e: any) => setSearchField(e.target.value, key),
      onBlur: (e: any) => {
        if (e.target.value.trim().length === 0)
          setSearchField(undefined, key)

        if (key.toLocaleLowerCase().indexOf("date") > 0) {
          if (key === "dispDate") {
            let obj = { ...searchFields }
            delete obj.dispDateFrom
            delete obj.dispDateTo
            setSearchFields(obj)
          } else setSearchField(undefined, "dispDate")
        }
      } //if empty space, set undefined
    }
  }

  return (
    <>
      {!newShipmentForm ? <>
        <Centralizer>
          <Container style={{ height: "70vh", maxHeight: "70vh", width: "clamp(0%, 100%, 1400px)" }}>
            <Group label={t("searchCriteria")}>
              <form onSubmit={(e) => searchShipment(e)}>
                <Grid>
                  <TextField {...props("trackingNo")}
                    title={t("enterConsOrPackageNo")}>{t("trackingNumber")}</TextField>
                  <TextField {...props("orderNo")} id="shipment-orderno">{t("orderNo")}</TextField>
                  <TextField {...props("receiver")}>{t("receiver")}</TextField>
                  <TextField {...props("carrier")} id="shipment-carrier">{t("carrier")}</TextField>
                  <TextField {...props("status")}>{t("status")}</TextField>
                  <TextField {...props("shipmentId")} id="shipment-shipmentId">{t("shipmentId")}</TextField>
                  <div className="w-full md:w-fit xl:w-full">
                    {!showInterval ?
                    <Grid>
                      <TextField {...props("dispDate")} _type="date">{t("dispDate")}</TextField>
                    </Grid>
                      :
                      <>
                        <Grid>
                          <TextField {...props("dispDateFrom")} _type="date">{t("dispDateFrom")}</TextField>
                          <TextField {...props("dispDateTo")} _type="date">{t("dispDateTo")}</TextField>
                        </Grid>
                      </>
                    }
                  </div> 
                </Grid>
         
                <Switch size='mini' onChange={() => setShowInterval(!showInterval)} checked={showInterval} label={t("showInterval")} />
                
                <Button className='w-full md:w-auto'>{t("search")}</Button>
                <Button className='w-full md:w-auto' onClick={() => { setSearchFields({}) }}>{t("clearsearch")}</Button>
              </form>
            </Group>
            <div className='w-full flex justify-end'>
              <Button className='' onClick={() => setNewShipmentForm(true)} variant="add">{t("createNewShipment")}</Button>
            </div>
            <>
              {shipments && shipments.length > 0 &&
                <div className="schipt-table-body">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("shipmentId")}</th>
                        <th>{t("carrier")}</th>
                        <th>{t("dispDate")}</th>
                        <th>{t("receiver")}</th>
                        <th>{t("orderNo")}</th>
                        <th>{t("status")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {shipments.map((s, i) => {
                        // console.log("WTF??s", s)
                        return (
                          <tr key={i} onDoubleClick={() => navigate(`/Shipment/${shipments[i].shipmentId}`)}>
                            <td onClick={() => navigate(`/Shipment/${shipments[i].shipmentId}`)}><FaMagnifyingGlass className='text-schipt-black dark:text-schipt-white' /></td>
                            <td>{s.shipmentId}</td>
                            <td>{s.carrier}</td>
                            <td>{s.dispDate ? format(new Date(s?.dispDate), 'yyyy-MM-dd') : ""}</td>
                            <td>
                              <div>{s.companyName}</div>
                              <div>{s.street1}</div>
                              <div>{s.countryCode + '-' + s.zipCode + ' ' + s.city}</div>
                            </td>
                            <td>{s.orderNo}</td>
                            <td className={`status flex content-center mt-4 ${s?.status == 10 ? 'bg-gray-200 text-gray-600' : s?.status == 20 ? 'bg-yellow-200 text-yellow-600' : s?.status == 50 ? 'bg-blue-200 text-blue-600' : s?.status == 70 ? 'bg-gray-400 text-gray-700' : s?.status == 80 ? 'bg-green-200 text-green-600' : s?.status == 90 ? 'bg-orange-200 text-orange-600' : 'bg-gray-200 text-gray-600'}`}>{t(`ta_statuses.${s?.status }`)}</td>
                            
                            <td>{s.trackingLink &&
                              <Button className="schipt-btn secondary" onClick={() => {
                                s.trackingLink && window.open(s.trackingLink, 'targetWindow',
                                  `scrollbars=yes,
                                    resizable=yes,
                                    width=500,
                                    left=500,
                                    height=500`)
                              }} _type="button" variant='submit'>Tracking</Button>
                            }
                            </td>
                          </tr>)
                      })
                      }
                    </tbody>
                  </table>
                </div>
              }
            </>
          </Container>
        </Centralizer>
      </>
        :
        <>
          <Button variant='delete' className='absolute z-10 schipt-btn secondary' onClick={() => setNewShipmentForm(false)}>Go Back</Button>
          <Shipment />
        </>
      }
      <ConfirmDialog options={confirmDialog as ConfirmDialogProps} />
      <TourStepper 
        hide={hideTour === "1"}
        onEnd={() => {
          localStorage.setItem("hideTour", "1")
          setHideTour("1")
        }}
        tourSteps={[
        {id:"navbar-shipments", header:t("shipments"), text:t("tour.shipments"),
          onNext: async () => {
            document.querySelector("li:has(#navbar-settings)")?.classList.add("active")
            return 20
          }, 
        },
        {id:"navbar-carriers", header:t("carriers"),text:t("tour.carriers"), orientation:"left",
          onBack: () => {document.querySelector("li:has(#navbar-settings)")?.classList.remove("active")}
        },
        {id:"navbar-deliverytypes", header:t("deliveryTypes"), orientation:"left", text:t("tour.deliveryTypes"),
          onNext: () => {
            document.querySelector("li:has(#navbar-profile)")?.classList.add("active")
            document.querySelector("li:has(#navbar-settings)")?.classList.remove("active")
            return 20
          }
        },
        {id:"navbar-profile", header:t("account.profile"), orientation:"left",text:t("tour.profile"),
          onBack: () => {
            document.querySelector("li:has(#navbar-settings)")?.classList.add("active")
            document.querySelector("li:has(#navbar-profile)")?.classList.remove("active")
            return 20
          }
        },
        {id:"navbar-subsriptions", header:t("account.subscription"), orientation:"left",text:t("tour.subscriptions")},
        {id:"navbar-users", header:t("account.userAdministration"), orientation:"left",text:t("tour.users"),
          onNext: () => {document.querySelector("li:has(#navbar-profile)")?.classList.remove("active")}
        },
      ]}/>
    </>
  );
}